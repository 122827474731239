
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faFilter, faFloppyDisk, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromHTML, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import graphIcon from '../../assets/images/graph-icon.svg';
import apiService from '../../core/apiService';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ScrollableListModal from '../../components/ScrollableListModal';
import premium from '../../assets/images/crown.png'
import nodata from '../../assets/images/Recipe book-pana.svg'
import html2canvas from 'html2canvas';
import CustomError from '../../components/CustomError';
import CustomSuccess from '../../components/CustomSuccess';
import { ReadMore } from '../../components/ReadMore';


const Dashboard = () => {
	const restaurant = useSelector(state => state.restaurant);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [textCount, setTextCount] = useState(0)
	const [selectedMessage, setSelectedMessage] = useState([])
	const [subscriptionName, setSubscriptionName] = useState('')
	const [isFav, setisFav] = useState(false);
	const [loader,setLoader]= useState(false)
	const [messagesList, setMessagesList] = useState([]);
	const [selectedId, setSelectedId] = useState(0)
	const navigate = useNavigate()
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = (e) => {
		e.preventDefault();
		if (selectedId) setShow(true);
	}
	const [dashboardDetails, setDashboardDetails] = useState([])
	const baseUrl = process.env.REACT_APP_URL + '/public/'

	const handleDownloadClick = async () => {
		let draftData = convertToRaw(editorState.getCurrentContent());
		const element = document.getElementById('htmlContent');
		let image, image_thumb = '';

		// Set the visibility property to 'visible'
		element.style.visibility = 'visible';
		element.style.width = '250px';
		element.style.height = '375px';

		const scale = 4; // Adjust this value based on your needs

		html2canvas(element, { scale: scale }).then((canvas) => {
			console.log(canvas.toDataURL(), "canvas");
			image = canvas.toDataURL();
			element.style.visibility = 'hidden';
			element.style.height = '250px';
			element.style.width = '375px';
			element.style.visibility = 'visible';

			html2canvas(element, { scale: 1 }).then((thumbCanvas) => {
				console.log(thumbCanvas.toDataURL(), "thumbnail canvas");
				image_thumb = thumbCanvas.toDataURL();
				element.style.visibility = 'hidden';

				// Now, make your API call
				apiService.post('/restaurants/create-sandwich', {
					restaurant_id: restaurant.id,
					image,
					image_thumb,
					sandwichText: encodeURIComponent(draftToHtml(draftData))
				}, ({ err, data }) => {
					if (data.success) {
						CustomSuccess('Sandwich Board  Saved successfully', 'Success')
						console.log(data);
					} else {
						console.log(err);
					}
				});
			});
		});
	};

	useEffect(() => {

		console.log("value inserted ", editorState, convertToRaw(editorState.getCurrentContent()).blocks, "blocks ")

		const count = convertToRaw(editorState.getCurrentContent()).blocks.map(item => item.text).join()

		console.log(count, count.length, "Count ,,,,,,,,,,,,,,,,,,,,,,,")
		setTextCount(count.length)
	}, [editorState])


	useEffect(() => {
		if (!selectedId) {
			setSelectedMessage([])
		} else {
			setSelectedMessage(messagesList?.filter((item) => item.id == selectedId))
		}
	}, [selectedId])

	const saveSandwichBoard = () => {

		let draftData = convertToRaw(editorState.getCurrentContent());
		if (convertToRaw(editorState.getCurrentContent()).blocks.length === 1 & draftData.blocks[0].text === '') {
			// toast.error('Please enter text on sandwich board', {
			// 	position: "top-center",
			// 	autoClose: 5500,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: false,
			// 	theme: "light",
			// });
			CustomError('Please enter text on sandwich board', '')
			return;
		}

		handleDownloadClick()
	}

	useEffect(() => {
		apiService.get(`/communications`, { restaurant_id: restaurant?.id }, ({ err, data }) => {
			if (err) {
				console.log(err);
				return;
			};
			setMessagesList(data.data);
		})

		apiService.get(`/restaurants/get-sandwich?restaurant_id=${restaurant.id}&enabled=1`, {}, ({ err, data }) => {
			if (data.success) {
				const decodedSandwichText = decodeURIComponent(data.data[0].sandwichText);
				let draft = convertFromHTML(decodedSandwichText);
				const state = ContentState.createFromBlockArray(
					draft.contentBlocks,
					draft.entityMap,
				);
				setEditorState(EditorState.createWithContent(state));
			} else {
				console.log(err);
			}
		});

		apiService.get(`/restaurants/dashboard/${restaurant.id}&enabled=1`, {}, ({ err, data }) => {
			if (data.success) {
				console.log(data.data)
				setDashboardDetails(data.data);
			} else {
				console.log(err);
			}
			apiService.get(`/restaurants/subscription/getpackage`, { restaurant_id: restaurant?.id }, ({ err, data }) => {
				if (err) return console.log(err);
				setSubscriptionName(data.data?.subscription?.subscription_name
				)

			})
		});
	}, []);



	const closeRestaurantModal = () => {
		setShow(false)
	}



	const sendNotification = () => {
		delete selectedMessage[0].created_at
		delete selectedMessage[0].updated_at
		
		apiService.post(`/restaurants/send-notification/${restaurant.id}`, { ...selectedMessage[0], image: selectedMessage[0]?.image ? baseUrl + selectedMessage[0]?.image : "" }, ({ err, data }) => {
			
			if(data.success){
			setLoader(false)
			handleClose()
			}
			if (err) console.log(err);
		})
	}


	const showPrium = (ele) => dashboardDetails.PremiumFeature?.find((item => item.feature.feature_name === ele)) ?
		<div className='img-left king-icon'><img className='img-premium img-king' src={premium} alt='' /> </div> : ''
	// const premiumMsg = (ele) => !dashboardDetails?.subscriptionFeatures?.find((item => item.feature.feature_name === ele)) ? <div className='img-left'><img className='img-premium' src={premium} alt='' /> </div> : ''
	const premiumStyle = (ele) => !dashboardDetails?.subscriptionFeatures?.find((item => item.feature.feature_name === ele)) ? { pointerEvents: "none", opacity: "0.4" } : {}

	return (
		<>
			<div className="dashboard-top-btn ">
				<button className="btn btn-dark" onClick={() => navigate('/daily-special/create', {})}><FontAwesomeIcon icon={faPencilAlt} /> Create Specials</button>
			</div>
			{/* <p>{subscriptionName == 'Basic' ? `Hey, right now you are on basic plan and these ${premium} features is not available` :''}</p> */}

			<div className='premium-feature-img'>
				{subscriptionName == 'Basic' ?

					<p><span>You are currently on the basic plan. Upgrade to access premium features. <span></span> </span> </p>
					: ''
				}

			</div>
			<div className="row">
				<div className="col-sm-6">
					<div className="dashboard-auto-wrapper mb-4 sandwich-board-box "
					>
						<div className='hover-text'>{showPrium("Virtual sandwich board")}
							<span class="tooltip-text" id="right">Premium</span>
						</div>

						<span
							style={premiumStyle("Virtual sandwich board")}

						>
							<div className="wrapper-head sandwich-board">
								<h3>Your Virtual Sandwich Board ({textCount}/250)
								</h3>
								<button onClick={saveSandwichBoard} disabled={textCount > 250 ? true : false} className={`btn${isFav === true ? " yellow" : ""} border-0 `} > <FontAwesomeIcon icon={faFloppyDisk} /></button>
							</div>
							<div className="row">
								<div className="col-md-12"
								>
									<div className={`form-group mb-4 ${textCount >= 250 ? 'border border-danger' : ''}`} >
										{/*	  <label className="form-label">Description(255 characters)</label>*/}
										<Editor
											editorState={editorState}
											onEditorStateChange={setEditorState}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											toolbar={{
												options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'emoji'],
												inline: {
													options: ['bold', 'italic', 'underline'],
												},
												fontSize: {
													options: [16, 18, 24],
												},
											}}
										/>
									</div>
								</div>
							</div>
						</span>
						{/* <div class="sandwichboard-lis text-cen">
						<img src={sandwichlist}/>
						</div> */}
					</div>
					<div className="dashboard-auto-wrapper"
					>
						{showPrium("Direct communication to followers")}
						<span
							style={premiumStyle("Direct communication to followers")}>
							<div className="wrapper-head">
								<h3>Communication to Followers</h3>
							</div>
							<div className="comunication-form">
								<form>
									<select className='form-control' onChange={(e) =>
										setSelectedId(e.target.value)
									}>
										<option value={''}>Select</option>
										{messagesList?.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
									</select>
									<button className="btn btn-dark" disabled={!selectedId} onClick={handleShow}>Send</button>
								</form>
							</div>

						</span>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="row">
						<div className="col-sm-6"
						>
							<div className="dashboard-auto-wrapper mb-3"
							>
								{showPrium("Favourite list")}
								<span
									style={premiumStyle("Favourite list")}>

									<div className="wrapper-head mt-2 ">
										<h3>Follower Trends</h3>
										<div className="filter-box">
											<button className="btn btn-dark"><FontAwesomeIcon icon={faFilter} /></button>
										</div>
									</div>
									<div className="follower-count-box">
										<span className="follower-icon"><img alt="" src={graphIcon} /></span>
										<h4>{dashboardDetails?.followers}</h4>
										<p> Followers</p>
									</div>
								</span>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="dashboard-auto-wrapper search_Table replies-popup">
								<div className="wrapper-head">
									<h3 className="text-center">Most Searched Items</h3>
								</div>
								<div className="table-responsive">
									<table>
										<thead>
											<tr>
												<th> Items</th>
												<th>#Search</th>
											</tr>
										</thead>
										<tbody>
											{dashboardDetails?.search?.length ? <>
												{
													dashboardDetails?.search?.slice(0, 3)?.map((item) => (
														<>
															<tr key={item.keyword}>
																<td>{item.keyword}</td>
																<td>{item.count}</td>
															</tr>
														</>
													))}</> :
												<div className="images-view text-center">
													<img className='do-data' src={nodata} alt='' />
													<p>No Data</p>	</div>}

										</tbody>
									</table>
									<ScrollableListModal visiblity={dashboardDetails?.search?.length > 3} componentToPassDown={<table>
										<thead>
											<tr>
												<th> Items</th>
												<th>#Search</th>
												<hr />
											</tr>
										</thead>
										<tbody>
											{dashboardDetails?.search?.map((item) => (
												<tr key={item.keyword}>
													<td>{item.keyword}</td>
													<td>{item.count}</td>
												</tr>
											))}
										</tbody>
									</table>} />
								</div>

							</div>
						</div>
					</div>
					<div className="dashboard-auto-wrapper margin-t30">
						<div className="wrapper-head">
							<h3 className="text-center">Replies from Admin</h3>
						</div>
						{/* <div className="dash-notification-list">
							{dashboardDetails?.reply?.slice(0, 3)?.map(item => <div className="dash-notification-box">
								<div className="notification-user-img">

								</div>
							 <div className="notification-con">
									<div className="noti-user-name">
										<div className='d-flex justify-content-between'>
											<span className='mx-2'>{item?.type?.type_name + `${((item?.status == 1) ? 'Accepted' : "Rejected")}`}</span>

											<span className='mx-2'>{item?.description}</span>
										</div>
										<div className='mt-2'><span className='mx-2'> {" Message :" + (item?.message == null ? '' : item.message)}</span></div>

									</div>
									<hr />
									<p></p>
								</div> 


							</div>)}
						</div> */}
						{/* new */}
						<div className='dash-notification-list'>
							{
								dashboardDetails?.reply?.slice(0, 3)?.map(item => (
									<>
										<div className='d-flex justify-content-between pt-2'>
											<div>
												<p>{item?.type?.type_name}- {item?.subject.length > 70 ? `${item?.subject.slice(0, 70)}...` : item?.subject}</p>

											</div>
											<div className='d-flex justify-content-around'>
												{/* <p className='approved'>Approve/Rejected</p> */}
												<div className=''>
													<p className='approved'>{((item?.status == 1) ? 
													item?.type_id == '3' ? <p className='approved'>Replied</p>:
														<p className='approved'>Approved</p> :
													 <p className='rejected'>Rejected</p>)}</p>

												</div>
												{/* <span>/</span> */}
												{/* <div className=''>
										<p className='rejected'>Rejected</p>

									</div> */}

											</div>
										</div>

										<p className='admin-message'>
											<ReadMore>
											{item?.message}
											{/* Drink or beveraDrink or beveraDrink or beveraDrink or beveraDrink or beveraDrink or beveraDrink or beveraDrink or beveraDrink or bevera */}
											</ReadMore>
											{/* {item?.message ? (
												<span className='message-preview'>
													{item?.message?.length > 15 ? `${item?.message.slice(0, 20)}...` : item.message}
												</span>
											) : ''} */}
										</p>
										<hr></hr>
									</>)
								)
							}


						</div>



						{/* new */}
					</div>
				</div>
				<div id="htmlContent" dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} style={{ visibility: 'hidden', width: '250px', height: '375px', objectFit: 'cover' }} />
				<Modal show={show} className="common-pop popup-medium notification-popup" onHide={closeRestaurantModal}>
					<Modal.Body className='text-center'>
						<div className='fix-notification'>
							<div className=''>

								<div className='title-box mt-4 pb-3 d-flex justify-content-center'>
									<img src={selectedMessage[0]?.image ? baseUrl + selectedMessage[0]?.image : ''} alt='No Image found ' />
									<div className='popup-deatils'><h5 className=''> Title  :  {selectedMessage[0]?.title} </h5>
										<h5 className='followers'> Followers  :  {dashboardDetails?.followers} </h5>
										<p className='mt-1'> {selectedMessage[0]?.message}</p>

									</div>
								</div>

							</div>
							<div className='sendNotification-btn pb-3 pt-2' >
								<button className="mx-  btn btn-success"  disabled={loader}  onClick={()=>{setLoader(true);sendNotification()}}> 
								{
                    loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Send Now'

                  }
									</button>
								<button className="mx-3  btn btn-danger" onClick={closeRestaurantModal}>Cancel</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div >

		</>
	);
};

export default Dashboard;